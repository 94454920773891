import './App.css';
import DownloadApp from './views/downloadApp';

function App () {
  return (
    <div className="App">
      <DownloadApp />
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { ReactComponent as AppleLogo } from "../../assets/images/svgs/download-apple-btn.svg";
import { ReactComponent as PlayStoreLogo } from "../../assets/images/svgs/download-playstore-btn.svg";
import styles from "./styles.module.css";

const DownloadApp = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.location.href = "vaalee://routeName=pendingPartnerInvite";
    const timeoutId = setTimeout(() => {
      const isIOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if (isIOS) {
        document.location.href =
          "https://apps.apple.com/us/app/vaalee/id6471491607";
      } else {
        document.location.href = "https://play.google.com/store/apps";
      }
      setLoading(false);
    }, 2000);
    return () => {
      setLoading(false);
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Box
      p={2}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Backdrop
        open={loading}
        style={{
          color: "#fff",
          zIndex: 2,
          backdropFilter: "blur(3px)",
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        className={`${styles.dFlexCol} ${styles.centerItems} ${styles.downloadAppContainer}`}
      >
        <div className={`${styles.dFlexCol} ${styles.gapM}`}>
          <h1 className={`${styles.textCenter} ${styles.textBold} ${styles.headerContainer}`}>
            Oops
          </h1>
          <p className={`${styles.textContent}`}>
            It looks like the link isn't working as expected. Don't worry, you
            can still access the app by clicking the links below
          </p>
        </div>
        <div className={`${styles.appLinks} ${styles.gapM}`}>
          <AppleLogo
            className={`${styles.cursorPointer}`}
            onClick={() =>
              window.open(
                "https://apps.apple.com/us/app/vaalee/id6471491607",
                "_blank"
              )
            }
          />
          <PlayStoreLogo
            className={`${styles.cursorPointer}`}
            onClick={() =>
              window.open(
                "https://play.google.com/store/apps",
                "_blank"
              )
            }
          />
        </div>
        <p className={`text-dark ${styles.textCenter} ${styles.footerTextContent}`}>
          We apologize for the inconvenience and appreciate your understanding.
          If you have any questions or need further assistance, please don't
          hesitate to contact us. Thank you!
        </p>
      </div>
    </Box>
  );
};

export default DownloadApp;
